import React, {useContext} from "react";
import './detailedInformation.css';
import {AppContext} from "../../context";
import { useParams, useLocation } from 'react-router-dom';
import {AccountsSection} from "../../components/tables/detailedInfoSections/accounts/accountsSection";
import {ClientPortfolio} from "../../components/tables/detailedInfoSections/portfolio/clientPortfolio";
import {WithdrawalSection} from "../../components/tables/detailedInfoSections/withdrawals/withdrawalSection";
import {StrategiesSection} from "../../components/tables/detailedInfoSections/strategies/strategiesSection";
import {InvestmentsSection} from "../../components/tables/detailedInfoSections/investments/investmentsSection";


export const DetailedInformation = () => {
    let { clientId } = useParams();
    let location = useLocation();

    const {name, totalTax, totalIra, currentPortfolio} = location.state.currentClientData;
    const {state, dispatch} = useContext(AppContext);


    return (
        <div className='client-container-grid'>
            <div style={state.adminMenuExpanded? {
                'height': '80px',
                'width': '10px',
                'animationName':'expand-desktop-space',
                'animationDuration':'1s',
            }: {'height':'1px'}}
            >
            </div>

            <div className='actual-screen'
                 key='actual-screen'
            >
                Consolidated allocation &emsp;>&emsp; Client ID : {clientId}
            </div>

            <ClientPortfolio clientId={clientId}
                             name={name}
                             currentPortfolio={currentPortfolio}
                             totalIra={totalIra}
                             totalTax={totalTax}
            />

            <div className='all-sections'>
                <div className='sections-grid'
                     key='sections-grid'
                >
                    <AccountsSection clientId={clientId} totalTax={totalTax} totalIra={totalIra}/>
                    <StrategiesSection clientId={clientId} currentPortfolio={currentPortfolio}/>
                    <InvestmentsSection clientId={clientId}/>
                </div>

                <WithdrawalSection name={name} clientId={clientId}/>
            </div>
        </div>
    );
};