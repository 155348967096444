import React, {useEffect} from "react";
import {useState} from "react";
import './requirementsTable.css';

import {numberFormatter} from "../../../../App";

export const RequirementsTable = (props) => {
    return(
        <div className='requirements-table'>
            {props.requirementsData.map((rows, index)=> {
                    return (
                        <div className='requirements-table-row' key={'requirements-table-row'+index}>
                            {rows.map((data, rowIndex)=> {
                                    const cleanData = typeof data === 'number'? numberFormatter(data) : data;
                                    let styleNum = {};
                                    let cleanTotal = cleanData;
                                    if (typeof data === 'number' && data < 0 && index === 2) {
                                        styleNum = {'color' : '#cb1010'};
                                        cleanTotal = `(${cleanData.replace(/[-]/g, '')})`
                                    }
                                    else if (typeof data === 'number' && data >= 0 && index === 2) {
                                        styleNum = {'color' : '#00704f'};
                                    }


                                    return (
                                        <div className='requirements-table-data'
                                             key={'requirements-investment'+rowIndex}
                                        >
                                            {rowIndex !== 0 && typeof data === 'number'?
                                                <span style={{'textAlign': 'left'}}>$</span> : ''
                                            }
                                                <p style={index === 2? styleNum : {}}
                                                >
                                                    {index === 2? cleanTotal : cleanData}
                                                </p>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    );
                }
            )}
        </div>
    );
};