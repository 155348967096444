import React, {useState} from "react";
import './investmentsTable.css';

import {numberFormatter} from "../../../../App";

export const InvestmentsTable = (props) => {
    const handleHighlightCell = (id) => {
        const p = document.getElementById('show-full-name'+id);
        p.setAttribute('style', 'transform: scale(1, 1); opacity: 1;');
    };

    const handleHideCell = (id) => {
        const p = document.getElementById('show-full-name'+id);
        p.setAttribute('style', 'transform: scale(0, 1); transition-duration: 0.15s;')
    };

    return(
        <div className='investments-table'>
            {props.investmentsData.map((rows, index)=> {
                    return (
                        <div className='investments-table-row' key={'investments-table-row'+index}>
                            {rows.map((data, rowIndex)=> {
                                    const cleanData = data === 0? '' : data;
                                    let cleanTitle = cleanData;
                                    // TODO: Uncomment these 3 lines in case the taxable names should be formatted and cleaned.
                                    // let cleanTitle = typeof data === "string"? data.replace(/[0-9,-]/g, ' ').toLowerCase() : '';
                                    // cleanTitle = cleanTitle.replace(/^\s+|\s+$/gm,'');
                                    // cleanTitle = cleanTitle.charAt(0).toUpperCase() + cleanTitle.slice(1);

                                    return (
                                        <div className='investments-table-data'
                                             key={'account-investment'+rowIndex}
                                        >
                                            {rowIndex !== 0 && data !== 0?
                                                <span style={{'textAlign': 'left'}}>$</span> : ''
                                            }

                                            {rowIndex !== 0?
                                                <p>
                                                    {typeof cleanData === 'number'? numberFormatter(cleanData) : cleanData}
                                                </p>
                                                :
                                                <div className='tax-acc-name-div'>
                                                    <p className='tax-acc-name'>
                                                        {cleanTitle}
                                                    </p>
                                                    <p className='show-full-name'
                                                       id={'show-full-name'+cleanTitle+index}
                                                    >
                                                        {cleanTitle}</p>
                                                    <div id={'tax-acc-name-div'}
                                                         onMouseEnter={() => handleHighlightCell(cleanTitle+index)}
                                                         onMouseLeave={() => handleHideCell(cleanTitle+index)}
                                                    >
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    );
                }
            )}
        </div>
    );
};